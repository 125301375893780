.avatar { text-align: center }
.form-container {
  padding-top: 20px;
  display: flex;
  justify-content: center;
}

.form {
  width: 460px;
}

.tab-action{
  margin-left: 40px;
}

.pagination {
  margin: 20px 0;
}

.pagination-controls {
  cursor: pointer;
  border-radius: 3px;
  color: #424242;
  margin: 0 10px;
}

.pagination-controls:hover {
  color: /*#ec407a*/white;
  background: #424242;
}

.large-width-menu {
  width: 240px;
}

 input span {
   color: #ec407a !important;
 }

.login-container {
  margin-top: 100px;
}

.search-field:focus {
  box-shadow: none !important;
}

.no-padding {
  padding: 0;
  margin: 0;
}

.info-value {
  color: #ec407a !important;
  font-weight: bold;
}
