.red-text {
    color: crimson;
}

.green-text {
    color: #7cb342;
}

body, html {
    color: #424242;
    background: #eee;
}